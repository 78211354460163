/** @jsx jsx */
import { Link } from 'gatsby';
import { Grid, Heading, Box, Text, jsx, Flex, Button } from 'theme-ui';
import BlogPostTileImage from '../components/BlogPostTileImage';
import BlogPostCategories from '../components/BlogPostCategories';
import { blogPostUrl } from '../lib/urls';

const BlogHeroPost = ({ post }) => {
  const postUrl = blogPostUrl(post.slug?.current);

  return (
    <Grid
      columns={[1, 12]}
      gap={6}
      sx={{
        gridColumnStart: 1,
        gridColumnEnd: [2, 3, 5, 13],
        mb: [5, 8],
      }}
    >
      <Box sx={{ gridColumnStart: [1, 7], gridColumnEnd: [2, 13] }}>
        <Link to={postUrl} sx={{ textDecoration: 'none' }}>
          <BlogPostTileImage post={post} />
        </Link>
      </Box>
      <Flex
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: [2, 6],
          gridRow: [2, 1],
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <BlogPostCategories categories={post.categories} />
        <Box>
          {post.title && (
            <Link to={postUrl} sx={{ textDecoration: 'none' }}>
              <Heading as="h2" sx={{ fontSize: [4, 5], mb: 3 }}>
                {post.title}
              </Heading>
            </Link>
          )}
          <Text sx={{ mb: 3 }}>{post.intro}</Text>
          <Button
            as={Link}
            to={postUrl}
            variant="secondary"
            sx={{ fontSize: 1 }}
          >
            Plus d'info
          </Button>
        </Box>
      </Flex>
    </Grid>
  );
};

export default BlogHeroPost;
