/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import BlogPostGrid from '../../components/BlogPostGrid';
import BlogHeroPost from '../../components/BlogHeroPost';

const BlogPage = ({ data: { posts }, location }) => {
  const showHeroPost = true;
  const heroPost = posts.nodes[0];
  const remainingPosts = showHeroPost ? posts.nodes.slice(1) : posts.nodes;

  return (
    <Layout location={location}>
      <SEO title="Blog" />
      {showHeroPost && <BlogHeroPost post={heroPost} />}
      <BlogPostGrid posts={remainingPosts} />
    </Layout>
  );
};

export const query = graphql`
  query {
    posts: allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(width: 600, height: 500)
          }
        }
        intro
        categories {
          id
          title
          color
          slug {
            current
          }
        }
      }
    }
  }
`;

export default BlogPage;
